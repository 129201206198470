import React, { useState } from "react";
import TextInput from "../ui/text-input";
import ImageInput from "../ui/image-input";
import axios from "axios";
import { BASE_URL } from "../../services/constants";
import { toast } from "react-toastify";

const UploadTemplate = ({ close, addToDrafts }) => {
  const [fileName, setFileName] = useState("");
  const [fileDesc, setFileDesc] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = (e) => {
    e.preventDefault();
    if (!selectedFile || !fileName || !fileDesc) {
      toast.error("Please fill in all fields and select a file!");
      return;
    }
    setIsUploading(true);
    const reader = new FileReader();
    reader.onload = function (event) {
      const fileContent = event.target.result;
      try {
        const uploadedTemplate = JSON.parse(fileContent);
        const draftTemplateData = {
          testName: fileName,
          testDescription: fileDesc,
          frequency: "5m",
          runtimePayload: uploadedTemplate,
        };
        axios
          .post(`${BASE_URL}`, draftTemplateData)
          .then((response) => {
            addToDrafts(response.data);
            toast.success("File uploaded successfully!");
            setIsUploading(false);
            close();
          })
          .catch((error) => {
            toast.error("Error uploading file. Please try again.");
            console.error("Error uploading file:", error);
            setIsUploading(false);
          });
      } catch (error) {
        toast.info(
          "Error parsing JSON file. Please ensure it is a valid JSON format."
        );
        setIsUploading(false);
      }
    };
    reader.readAsText(selectedFile[0]);
  };

  return (
    <div>
      <form>
        <div>
          <TextInput
            type={"text"}
            placeholder={"Enter test name"}
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            label={"Test Name"}
          />
        </div>
        <div>
          <TextInput
            type={"text-area"}
            placeholder={"Enter test description"}
            value={fileDesc}
            onChange={(e) => setFileDesc(e.target.value)}
            label={"Test Description"}
          />
        </div>
        <div className="px-3">
          <ImageInput
            label="Select JSON file"
            setImage={setSelectedFile}
            containerClass=""
          />
        </div>
        <div className="mt-4 px-2">
          <button
            onClick={handleFileUpload}
            className="btn-int w-full flex justify-center gap-x-2 items-center"
          >
            {isUploading ? "Uploading..." : "Upload Template"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UploadTemplate;
