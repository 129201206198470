import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { ABSOLUTE_BASE_URL } from "../../services/constants";

const ShowLast10Runs = ({ id, show, onClose }) => {
  const [last10Runs, setLast10Runs] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id) {
      axios.get(`${ABSOLUTE_BASE_URL}/v1/testlogs/statistics/uuid/${id}`)
        .then(response => {
          setLast10Runs(response.data);
          setError(null);
        })
        .catch(error => {
          console.error('Error fetching last 10 runs:', error);
          setError("Failed to retrieve the last 10 runs. Please try again later.");
        });
    }
  }, [id]);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className="bg-neutral-800 text-white">
        <Modal.Title style={{ fontSize: '1.25rem' }}>Last 10 Runs</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white">
        {error ? (
          <p className="text-red-500" style={{ fontSize: '0.875rem' }}>{error}</p>
        ) : (
          <Table striped bordered hover className="text-center" style={{ fontSize: '0.875rem' }}>
            <thead>
              <tr style={{ backgroundColor: 'black', color: 'white' }}> {/* Black background, white text */}
                <th>Status Code</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(last10Runs).map(([statusCode, count]) => (
                <tr key={statusCode}>
                  <td className="px-3 py-2 font-medium">{statusCode}</td>
                  <td className="px-3 py-2">{count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer className="bg-neutral-100">
        <Button variant="secondary" onClick={onClose} className="px-4 py-2">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowLast10Runs;