import { IoIosCloudUpload } from "react-icons/io";
import { IoCloudDownload } from "react-icons/io5";
import { VscNotebookTemplate } from "react-icons/vsc";
import useDialog from "../hooks/useDialog";
import UploadTemplate from "../components/draft-test/upload-template";
import { useEffect, useState } from "react";
import { BASE_URL } from "../services/constants";
import axios from "axios";
import DraftTable from "../components/draft-test/draft-table";
import jsonImg from "../assets/json.png";

const HomePage = () => {
  const [viewType, setViewType] = useState("upload");
  const [draftTemplates, setDraftTemplates] = useState([]);
  const { Dialog, setShowDialog } = useDialog();

  //   fetch draft templates
  useEffect(() => {
    axios
      .get(`${BASE_URL}/type/0`)
      .then((response) => setDraftTemplates(response.data))
      .catch((error) =>
        console.error("Error fetching draft templates:", error)
      );
  }, []);

  //   add to draft templates
  const addToDrafts = (data) => {
    const newDraft = [...draftTemplates, data];
    setDraftTemplates(newDraft);
  };

  //   download draft template
  const downloadTemplate = () => {
    const sampleTemplate = {
      url: "mywebsite.com",
      messageToDisplay: "This is a sample message",
      applicationFlow: "Sample Flow",
      actions: [
        {
          action: {
            actionName: "Action 1",
            description: "First action description",
            timeout: "5",
          },
        },
        {
          action: {
            actionName: "Action 2",
            description: "Second action description",
            timeout: "5",
          },
        },
      ],
      result: {},
    };

    const blob = new Blob([JSON.stringify(sampleTemplate, null, 2)], {
      type: "application/json",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template.json");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div className="h-[calc(100%-1.75rem)]">
      <div className="place-center h-full">
        <div className="relative w-[50rem] min-h-[25rem] bg-gray-50 bg-opacity-20 shadow-lg p-3 lg:p-6 rounded-xl">
          <div className="relative z-10">
            <div className="flex justify-between items-center">
              <p className="syne font-[700] text-xl text-white">
                Manage Draft Templates
              </p>
              {viewType === "upload" ? (
                draftTemplates.length ? (
                  <div
                    className="flex gap-x-2 items-center cursor-pointer hover:scale-105 duration-100"
                    onClick={() => setViewType("table")}
                  >
                    <p className="bg-orange-500 w-4 h-4 rounded-full place-center text-[.7rem] font-[600] text-white">
                      {draftTemplates.length}
                    </p>
                    <p className="text-[.9rem] font-[500]">View Draft</p>
                  </div>
                ) : (
                  ""
                )
              ) : (
                <p
                  className="text-[.9rem] font-[500] cursor-pointer hover:scale-105 duration-100"
                  onClick={() => setViewType("upload")}
                >
                  Upload Template
                </p>
              )}
            </div>
            {viewType === "upload" && (
              <div className="mt-4 grid gap-5 grid-cols-2">
                <div className="bg-white p-2 rounded-xl">
                  <div className="px-4 bg-gray-100 rounded-t-xl h-[9rem] overflow-hidden">
                    <img
                      src={jsonImg}
                      alt="json-img"
                      className="w-9/12 mx-auto"
                    />
                  </div>
                  <div className="p-2">
                    <p className="text-[.9rem]">
                    <span className="font-[500]">Build custom test cases.</span> Upload them here to save time and ensure you are following the correct format.
                    </p>
                    <div>
                      <button
                        className="btn-int w-full flex justify-center gap-x-2 items-center"
                        onClick={() => setShowDialog(true)}
                      >
                        <IoIosCloudUpload /> Upload Draft Template
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-2 rounded-xl">
                  <div className="p-4 bg-gray-100 rounded-t-xl h-[9rem] place-center">
                    <div className="flex items-end gap-x-2">
                      <VscNotebookTemplate className="text-4xl lg:text-5xl text-purple-600" />
                      <div>
                        <p className="text-[.7rem] leading-[1px] text-purple-600">
                          test-template.csv
                        </p>
                        <p className="text-[.7rem] leading-[1px] text-gray-500">
                          123kb
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <p className="text-[.9rem]">
                      <span className="font-[500]">
                        Unsure about how to write your test?
                      </span>{" "}
                      Download the template below to ensure you are following
                      the correct format.
                    </p>
                    <div>
                      <button
                        className="btn-int w-full flex justify-center gap-x-2 items-center"
                        onClick={downloadTemplate}
                      >
                        <IoCloudDownload /> Download Template
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {viewType === "table" && (
              <div>
                <DraftTable
                  draftTemplates={draftTemplates}
                  setDraftTemplates={setDraftTemplates}
                />
              </div>
            )}
          </div>
          <div className="absolute top-0 left-0 w-full h-full backdrop-blur rounded-xl"></div>
        </div>
      </div>
      <Dialog title={"Upload Draft Template"} size={"md"}>
        <UploadTemplate
          close={() => setShowDialog(false)}
          addToDrafts={addToDrafts}
        />
      </Dialog>
    </div>
  );
};

export default HomePage;
