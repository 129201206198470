import React from "react";
import TestTable from "../components/manage-test/test-table";

const TestPage = () => {
  return (
    <div className="h-[calc(100%-1.75rem)]">
      <div className="place-center h-full">
        <div className="relative w-[90dvw] h-[80dvh] bg-gray-50 bg-opacity-20 shadow-lg p-3 lg:px-6 rounded-xl">
          <div className="relative z-10">
            <div className="flex justify-between items-center">
              <p className="syne font-[700] text-xl text-white">
                Manage Monitoring Tests
              </p>
            </div>
            <TestTable />
          </div>
          <div className="absolute top-0 left-0 w-full h-full backdrop-blur rounded-xl"></div>
        </div>
      </div>
    </div>
  );
};

export default TestPage;
