import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import TextInput from "../ui/text-input";
import { BASE_URL } from "../../services/constants";

const EditTemplateModal = ({
  editedContent,
  template,
  close,
  draftTemplates,
  setDraftTemplates,
}) => {
  const [editContent, setEditContent] = useState(editedContent || "");
  const [isEditing, setIsEditing] = useState(false);

  const handleSaveEdit = () => {
    setIsEditing(true);
    try {
      const updatedTemplate = JSON.parse(editedContent);

      // Ensure id is not edited
      updatedTemplate.id = template.id;

      axios
        .put(`${BASE_URL}/${template.id}`, updatedTemplate)
        .then(() => {
          setDraftTemplates(
            draftTemplates.map((t) =>
              t.id === template.id ? updatedTemplate : t
            )
          );
          toast.success("Template updated successfully!");
          setIsEditing(false);
          close();
        })
        .catch((error) => {
          toast.error("Error updating template:", error);
          setIsEditing(false);
        });
    } catch (err) {
      toast.error("Invalid JSON format!");
      setIsEditing(false);
    }
  };

  return (
    <div>
      <form>
        <div>
          <TextInput
            type={"text-area"}
            placeholder={""}
            value={editContent}
            onChange={(e) => setEditContent(e.target.value)}
            label={"Edit JSON Template"}
            altClass={"h-60"}
          />
        </div>
        <div className="mt-4 px-2 flex justify-between items-center">
          <button
            className="py-2 px-3 lg:px-6  bg-gray-600 capitalize rounded-lg text-white"
            onClick={close}
          >
            Close
          </button>
          <button
            onClick={handleSaveEdit}
            className="btn-int px-6 flex justify-center gap-x-2 items-center"
          >
            {isEditing ? "Updating..." : "Update Template"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditTemplateModal;
