import React from "react";
import ReusableSearchBox from "../ui/reusable-search";
import { FormatStatus } from "../ui/format-status";

const TestFilters = ({ searchValue, setSearchValue, currentStatus, setCurrentStatus }) => {
  const statusList = ["all", "running", "stopped", "failed"];

  return (
    <div>
      <div className="flex justify-between items-end">
        <div className="flex items-end gap-x-3">
          {statusList.map((item) => (
            <div
              className={`rounded-full cursor-pointer border-[3px] ${
                currentStatus === item ? " border-purple-600" : " border-transparent"
              }`}
              onClick={() => setCurrentStatus(item)}
            >
              {FormatStatus[item]}
            </div>
          ))}
        </div>
        <div className="w-[20rem]">
          <ReusableSearchBox param={searchValue} setParams={setSearchValue}/>
        </div>
      </div>
    </div>
  );
};

export default TestFilters;
