import React from "react";
import "./index.css";

const TextInput = ({ value, onChange, label, type, placeholder, altClass }) => {
  return (
    <div class="input-container">
      {type === "text-area" ? (
        <textarea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`${altClass} input-field`}
        />
      ) : (
        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="input-field"
          type={type}
        />
      )}
      <label for="input-field" className="input-label">
        {label}
      </label>
      <span className="input-highlight"></span>
    </div>
  );
};

export default TextInput;
