import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { RiDraftLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const { pathname } = useLocation();
  console.log(pathname);

  return (
    <div className="flex justify-between items-center">
      <p className="font-[700] syne text-xl text-white">
        Monitoring UI Demo-Version 1.5
      </p>
      <ul className="flex gap-x-7 px-6">
        <li>
          <Link
            to={"/"}
            className={` font-[500] flex gap-x-1 items-center no-underline ${
              pathname === "/" ? "text-orange-800" : "text-black"
            }`}
          >
            <RiDraftLine color="" />
            Manage Draft Templates
          </Link>
        </li>
        <li>
          <Link
            to={"/tests"}
            className={` font-[500] flex gap-x-1 items-center no-underline ${
              pathname === "/tests" ? "text-orange-800" : "text-black"
            }`}
          >
            <FontAwesomeIcon icon={faGear} />
            Manage Tests
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Header;
