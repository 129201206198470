import { RiDeleteBin5Fill } from "react-icons/ri";
import { PiSealWarningFill } from "react-icons/pi";

const ReusableModal = ({
  title,
  closeModal,
  action,
  cancelTitle,
  actionTitle,
  type,
}) => {
  return (
    <div className="px-4">
      {type === "warning" ? (
        <div className="bg-orange-100 w-24 h-24 circle rounded-lg mx-auto place-center">
          <PiSealWarningFill className="text-5xl text-orange-600" />
        </div>
      ) : (
        <div className="bg-red-100 w-24 h-24 circle rounded-lg mx-auto place-center">
          <RiDeleteBin5Fill className="text-3xl text-red-600" />
        </div>
      )}
      <div className="px-6 mt-3 text-black dark:text-white text-center">
        {title}
      </div>
      <div className="w-full mt-8 flex justify-between">
        <button
          className="py-2 px-3 lg:px-6  bg-gray-600 capitalize rounded-lg text-white"
          onClick={closeModal}
        >
          {cancelTitle}
        </button>
        <button
          className="py-2 px-3 lg:px-6 rounded-lg btn-int capitalize text-white dark:bg-darkColor"
          onClick={action}
        >
          {actionTitle}
        </button>
      </div>
    </div>
  );
};

export default ReusableModal;
