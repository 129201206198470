
import React, { useState, useCallback} from 'react';
import { useDropzone } from 'react-dropzone';
import { AiFillPicture } from 'react-icons/ai';
import jsonFile from "../../assets/json.png"

const ImageInput = ({
  label,
  setImage,
  containerClass,
  prevValue,
}) => {
  const [preview, setPreview] = useState(prevValue);
  //   handle drag and drop
  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles);
    const file = new FileReader();
    file.onload = function () {
      setPreview(file.result);
    };
    file.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  return (
    <>
      <div className={`${containerClass}`}>
        <p className="text-[#767676] mb-2">{label}</p>
        <div
          {...getRootProps()}
          className="w-full border border-gray-400 rounded-lg min-h-[120px] p-4 flex justify-between items-center"
        >
          <input {...getInputProps()} accept="application/json" />
          {preview ? (
            ""
          ) : isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <div className="flex items-center gap-x-4">
              <div className="w-12 h-12 rounded-[5px] bg-[#122e5735] grid place-content-center">
                <AiFillPicture className="text-[#122E57] text-xl" />
              </div>
              <p className="text-[#767676] fs-400">
                Drop files here or browse files to upload
              </p>
            </div>
          )}
          {preview && (
            <img
              src={jsonFile}
              alt="Upload preview"
              className="w-24 mx-auto"
              width={200}
              height={200}
            />
          )}
          <div>
            <div className="border border-[#D2D2D2] rounded-[4px] px-7 py-[.45rem] h-10 cursor-pointer">
              <p>Browse</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageInput;
