export const FormatStatus = {
  running: (
    <p className="px-3 py-1 m-0 rounded-full bg-green-100 text-green-600 syne font-[600]">
      Running
    </p>
  ),
  stopped: (
    <p className="px-3 py-1 m-0 rounded-full bg-orange-100 text-orange-600 syne font-[600]">
      Stopped
    </p>
  ),
  failed: (
    <p className="px-3 py-1 m-0 rounded-full bg-red-100 text-red-600 syne font-[600]">
      Failed
    </p>
  ),
  all: (
    <p className="px-3 py-1 m-0 rounded-full bg-blue-100 text-blue-600 syne font-[600]">
      All
    </p>
  ),
};
