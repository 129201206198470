import React from "react";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";

const ViewTemplateModal = ({ template, close }) => {
  return (
    <div>
      <div className="pt-4 max-h-[30rem] overflow-y-auto">
        {template && (
          <JSONPretty
            id="json-pretty"
            data={template}
            replacer={function (key, value) {
              if (key == "runtimePayload") {
                return JSON.parse(value);
              }
              return value;
            }}
          ></JSONPretty>
        )}
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={close}
          className="btn-int px-5 flex justify-center gap-x-2 items-center"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ViewTemplateModal;
