import React from "react";
import { BsGithub } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="">
      <div className="absolute bottom-0 left-0 pr-4 w-full flex items-center justify-between">
        <div>
          <ul className="flex">
            <li>
              <Link
                to={"https://github.com/virgile-rapegno/ini-practicum"}
                className="no-underline text-orange-500 font-[500] flex items-center gap-x-1"
              >
                <BsGithub />
                <span>Github</span>
              </Link>
            </li>
          </ul>
        </div>
        <p>© 2024, Monitoring UI Demo-Version. All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
