import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import ManageTests from './components/ManageTests';
import ManageDraftTemplates from './components/ManageDraftTemplates'; // 引入 Draft Template 管理页面
import 'bootstrap/dist/css/bootstrap.min.css'; // 引入 Bootstrap 样式
import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import Header from './layout/header';
import Footer from './layout/footer';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TestPage from './pages/test';

function App() {
  const [view, setView] = useState('drafts'); // 默认显示 Manage Draft Templates 视图

  return (
    <div className="layout-backgroud">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/tests" element={<TestPage />} />
      </Routes>
      <Footer />
      {/* <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">Monitoring UI Demo-Version 1.5</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                onClick={(event) => {
                  event.preventDefault();
                  setView("drafts");
                }}
              >
                Manage Draft Templates
              </Nav.Link>
              <Nav.Link
                onClick={(event) => {
                  event.preventDefault();
                  setView("manage");
                }}
              >
                Manage Tests
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container>
        {view === "drafts" && <ManageDraftTemplates />}
        {view === "manage" && <ManageTests />}
      </Container> */}
    </div>
  );
}

export default App;
