import { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { useDebouncedCallback } from "use-debounce";

const ReusableSearchBox = ({ setParams, placeholder }) => {
  const [value, setValue] = useState("");
  const debounced = useDebouncedCallback((value) => {
    setParams(value);
  }, 1000);
  
  useEffect(() => {
    debounced(value);
  }, [value]);

  return (
    <div className="w-full flex items-center gap-x-2 border border-[#B2B0B0] bg-[#E9EBFB] dark:bg-[#131313] rounded-full px-2 py-[3px]">
      <input
        type="search"
        value={value}
        placeholder={placeholder ? placeholder : "search for items"}
        className="border-none fs-400 outline-none bg-transparent p-[6px] w-full"
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="w-[38px] h-[29px] place-center bg-[#122e57] rounded-[30px]">
        <LuSearch className="shrink-0 text-white text-lg" />
      </div>
    </div>
  );
};

export default ReusableSearchBox;
