
import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../services/constants";
import TestFilters from "./filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTrash,
  faPlay,
  faStop,
  faList,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import useDialog from "../../hooks/useDialog";
import ViewTemplateModal from "../draft-test/view-template";
import ReusableModal from "../ui/reusable-modal";
import { toast } from "react-toastify";
import { FormatStatus } from "../ui/format-status";
import dayjs from "dayjs";
import ShowLast10Runs from './showlast10runs.js';


const TestTable = () => {
  const [tests, setTests] = useState([]);
  const [testsToRender, setTestsToRender] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [showLast10Modal, setShowLast10Modal] = useState(false);
  const [last10RunsId, setLast10RunsId] = useState(null); // Store the ID to fetch


  //   modals
  const { Dialog: ViewTemplate, setShowDialog: ShowViewTemplate } = useDialog();
  const { Dialog: DeleteTemplate, setShowDialog: ShowDeleteTemplate } =
    useDialog();
  const { Dialog: PromoteTemplate, setShowDialog: ShowPromoteTemplate } =
    useDialog();

  // modal controls
  // handle preview template
  const handlePreview = (template) => {
    setSelectedTemplate(template);
    ShowViewTemplate(true);
  };

  // open delete
  const openDelete = (id) => {
    setTemplateId(id);
    ShowDeleteTemplate(true);
  };

  // open promote
  const openDemote = (template) => {
    setSelectedTemplate(template);
    ShowPromoteTemplate(true);
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/type/1`)
      .then((response) => {
        setTests(response.data);
        setTestsToRender(response.data);
      })
      .catch((error) => console.error("Error fetching tests:", error));
  }, []);

  //   filter by status
  useEffect(() => {
    if (currentStatus) {
      if (currentStatus === "all") {
        setTestsToRender(tests);
        return;
      }
      const filtered = tests.filter((where) => where.status === currentStatus);
      setTestsToRender(filtered);
    }
  }, [currentStatus]);

  //   filter by search
  useEffect(() => {
    if (searchValue) {
      const filtered = tests.filter((item) => {
        const nameMatch = item.testName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        const idMatch = item.id
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        const frequencyMatch = item.frequency
          .toLowerCase()
          .includes(searchValue.toLowerCase());

        return nameMatch || idMatch || frequencyMatch;
      });
      setTestsToRender(filtered);
    } else {
      setTestsToRender(tests);
    }
  }, [searchValue]);

  //   Test Actions
  const deleteTest = () => {
    axios
      .delete(`${BASE_URL}/${templateId}`)
      .then(() => {
        setTests(tests.filter((test) => test.id !== templateId));
        toast.success("Test deleted successfully");
      })
      .catch((error) => console.error("Error deleting test:", error));
  };

  const startTest = (id) => {
    axios
      .patch(`${BASE_URL}/promote/start/${id}`)
      .then((response) => {
        const updatedTest = response.data;
        setTests(
          tests.map((test) =>
            test.id === id
              ? {
                  ...test,
                  status: updatedTest.status,
                  lastRunTime: updatedTest.lastRunTime,
                }
              : test
          )
        );
        toast.success("Test started successfully");
      })
      .catch((error) => console.error("Error starting test:", error));
  };

  const stopTest = (id) => {
    axios
      .patch(`${BASE_URL}/promote/stop/${id}`)
      .then((response) => {
        const updatedTest = response.data;
        setTests(
          tests.map((test) =>
            test.id === id
              ? {
                  ...test,
                  status: updatedTest.status,
                  lastRunTime: updatedTest.lastRunTime,
                }
              : test
          )
        );
        toast.success("Test stopped successfully");
      })
      .catch((error) => console.error("Error stopping test:", error));
  };

  const showResults = (id) => {
    const url = `https://search-demo-1001-cwrsl2v6lxdhqyast4hdaavkiu.aos.us-east-1.on.aws/_dashboards/app/data-explorer/discover#?_a=(discover:(columns:!(_source),isDirty:!f,sort:!()),metadata:(indexPattern:d1a114b0-8574-11ef-baf4-114f1b306c82,view:discover))&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&_q=(filters:!(),query:(language:kuery,query:${id}))`;
    //search-demo-1001-cwrsl2v6lxdhqyast4hdaavkiu.aos.us-east-1.on.aws/_dashboards/app/data-explorer/discover#?_a=(discover:(columns:!(_source),isDirty:!f,sort:!()),metadata:(indexPattern:d1a114b0-8574-11ef-baf4-114f1b306c82,view:discover))&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&_q=(filters:!(),query:(language:kuery,query:''))
    window.open(url, "_blank").focus();
  };

  // show the last 10 runs 
  const handleShowLast10Runs = (id) => {
    setLast10RunsId(id);
    setShowLast10Modal(true);
  };
  

  return (
    <div>
      <div>
        <TestFilters
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
        />
        <div className="flex flex-col h-[60dvh] mt-4 overflow-y-auto">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <div className="overflow-hidden">
                <table className="min-w-full text-center text-sm font-light">
                  <thead className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className=" px-4 text-center py-2">
                        Test Id
                      </th>
                      <th scope="col" className=" px-4 text-center py-2">
                        Test Name
                      </th>
                      <th scope="col" className=" px-4 text-center py-2">
                        Status
                      </th>
                      <th scope="col" className=" px-4 text-center py-2">
                        Last Runtime
                      </th>
                      <th scope="col" className=" px-4 text-center py-2">
                        Frequency
                      </th>
                      <th scope="col" className=" px-4 text-center py-2">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {testsToRender.map((test) => (
                      <tr key={test.id}>
                        <td className="whitespace-nowrap text-[#000205] bg-white border-r border-b bg-opacity-30 py-3 font-medium">
                          {test.id}
                        </td>
                        <td className="whitespace-nowrap bg-white border-r border-b bg-opacity-30  px-3 py-3">
                          {test.testName}
                        </td>
                        <td className="whitespace-nowrap bg-white border-r border-b bg-opacity-30  px-3 py-3">
                          {FormatStatus[test.status || "pending"]}
                        </td>{" "}
                        <td className="whitespace-nowrap bg-white border-r border-b bg-opacity-30  px-3 py-3">
                          {test.lastRunTime
                            ? dayjs(test.lastRunTime).format(
                                "hh:mm, DD dddd, MMM YYYY"
                              )
                            : "N/A"}
                        </td>{" "}
                        <td className="whitespace-nowrap bg-white border-r border-b bg-opacity-30  px-3 py-3">
                          {test.frequency || "N/A"}
                        </td>{" "}
                        <td className="whitespace-nowrap bg-white border-r border-b bg-opacity-30  px-3 py-3">
                          <button
                            className="btn-int p-1 px-2"
                            onClick={() => handlePreview(test)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                          <button
                            className="btn-int p-1 px-2"
                            onClick={() => startTest(test.id)}
                          >
                            <FontAwesomeIcon icon={faPlay} />
                          </button>
                          <button
                            className="btn-int p-1 px-2"
                            onClick={() => stopTest(test.id)}
                          >
                            <FontAwesomeIcon icon={faStop} />
                          </button>
                          <button
                            className="btn-int p-1 px-2"
                            onClick={() => openDelete(test.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          {/* <button  className="btn-int p-1 px-2" onClick={() => unpromoteTest(test.id)}>
                  <FontAwesomeIcon icon={faArrowDown} />
                </button> */}
                          <button
                            className="btn-int p-1 px-2"
                            onClick={() => showResults(test.id)}
                          >
                            <FontAwesomeIcon icon={faList} />
                          </button>
                          <button
                            className="btn-int p-1 px-2" // 使用相同的类名以保持一致的样式
                            onClick={() => handleShowLast10Runs(test.id)}
                            style={{ marginLeft: '5px' }}
                          >
                            <FontAwesomeIcon icon={faHistory} /> {/* 使用相同的 faList 图标，或改成 faHistory */}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
          {showLast10Modal && (
      <ShowLast10Runs
        id={last10RunsId}         // Pass the ID to fetch the results
        show={showLast10Modal}    // Control modal visibility
        onClose={() => setShowLast10Modal(false)}  // Close handler
      />
    )}

      <ViewTemplate title={"View Test Template"} size={"lg"}>
        <ViewTemplateModal
          template={selectedTemplate}
          close={() => ShowViewTemplate(false)}
        />
      </ViewTemplate>
      <DeleteTemplate title={""} size={"sm"}>
        <ReusableModal
          title={"Are you sure you want to delete this test?"}
          type={"delete"}
          actionTitle={"Yes, Delete"}
          closeModal={() => ShowDeleteTemplate(false)}
          cancelTitle={"No, Close"}
          action={deleteTest}
        />
      </DeleteTemplate>
      {/* <PromoteTemplate title={""} size={"sm"}>
        <ReusableModal
          title={"Are you sure you want to promote this template from draft?"}
          type={"warning"}
          actionTitle={"Yes, Promote"}
          closeModal={() => ShowPromoteTemplate(false)}
          cancelTitle={"No, Close"}
          action={handleConfigure}
        />
      </PromoteTemplate> */}
    </div>
  );
};

export default TestTable;